import React, { useState } from 'react'
import { Card, Col, Container, Modal, Row } from 'react-bootstrap'
import './Projects.css'
import { BiSolidCarGarage } from 'react-icons/bi';
import { FaGuitar, FaRegRegistered } from 'react-icons/fa6';
import { MdFrontLoader, MdOutlineConnectedTv } from 'react-icons/md';
import { FaCalculator } from 'react-icons/fa';
import { PiExamFill } from 'react-icons/pi';
function Projects() {

  const [selectedProject,setSelectedproject] = useState(null);
  const [showModal,setShowModal] = useState(false);
  const handleShowModal = ()=> setShowModal(true);
  const handleHideModal = ()=> setShowModal(false);

  const handleSelectedProject = (i)=>{
    
      setSelectedproject(i);
      handleShowModal();
  }


  const webAppProjects = [
    {
      icon:<MdOutlineConnectedTv />,
      title: 'OTT Platform',
      subtitle: 'An enterprise-level OTT platform designed for seamless video streaming and user engagement.',
      desc: <>
        <p>In this project, I was responsible for the frontend development, where I built an intuitive and responsive web application using React. The platform supports high-quality video streaming, and I implemented an HLS (HTTP Live Streaming) player to ensure smooth and adaptive video playback across various devices and network conditions.</p>
        <p>To manage the application's state efficiently, I utilized Redux, which provided a centralized state management system, making the app more predictable and easier to debug. For handling API calls and caching data, I integrated Redux Toolkit Query. This allowed for streamlined data fetching, caching, synchronization, and updating of the UI based on the server's state, all with minimal boilerplate code.</p>
        <p>The result was a visually appealing and highly functional OTT platform that delivers an exceptional user experience to more than 25k users and growing.</p>
      </>
    },
    {
      icon: <PiExamFill />,
      title: 'Test & Exam Platform',
      subtitle: 'An advanced platform designed for specialized testing and management, featuring distinct interfaces for users, professors, and administrators.',
      desc: <>
        <p>This project features a comprehensive system developed using React for the frontend, with three distinct panels:</p>
        <ul>
          <li><strong>User End:</strong> The user-facing panel where end users can take tests across various specialties. The interface is built with React and Redux for smooth navigation and real-time state management.</li>
          <li><strong>Prof Panel:</strong> Professors use this panel to manage questions, tests, topics, and subtopics. Redux Toolkit and Redux Toolkit Query handle state management and API calls, ensuring efficient data caching and synchronization.</li>
          <li><strong>Admin Panel:</strong> The Admin Panel extends the Prof Panel's functionality, adding capabilities for managing domains, subdomains, and professors. It uses React Router for complex routing and access control, ensuring seamless management of the entire platform.</li>
        </ul>
        <p>The backend is powered by Node.js and Express, with MongoDB as the database, providing a secure and scalable solution. JWT is used for secure authentication, and AWS S3 handles media content storage, making the platform robust and scalable.</p>
      </>
    },
    {
      icon: <FaCalculator />,
      title: 'Property Investment Calculator',
      subtitle: 'A comprehensive property investment calculator with a multi-step form for user inputs.',
      desc: <>
        <p>In this project, I worked on the frontend development of a property investment calculator. The application featured a multi-step form that guided users through entering various parameters related to property investment.</p>
        <p>Each step of the form was designed to be user-friendly and interactive, ensuring that users could easily input data on factors such as property price, loan details, rental income, and other investment criteria.</p>
        <p>The goal was to create an intuitive and accurate tool that helps users make informed decisions about their property investments.</p>
      </>
    },
    {
      icon: <FaGuitar />,
      title: 'Music Platform',
      subtitle: 'A music platform that compares two separate guitar strings, with a focus on backend development and an admin panel.',
      desc: <>
        <p>This project involved the development of a music platform that allows users to compare two separate guitar strings. My primary focus was on the backend, where I used Node.js to build a robust and scalable server that could handle data processing and user interactions seamlessly.</p>
        <p>I also created an admin panel, enabling administrators to manage content, users, and comparisons. The admin panel was designed for easy navigation and efficient management, ensuring that the platform could be maintained and updated with minimal effort.</p>
      </>
    },
    {
      icon:<BiSolidCarGarage />,
      title: 'Car Warranty Website',
      subtitle: 'A warranty purchase website for cars and vans, featuring a rich UI and comprehensive user dashboard.',
      desc: <>
        <p>In this project, I worked on the frontend development of a warranty purchase website for cars and vans. The website featured various sections with a lot of content, animations, effects, and a user-friendly interface.</p>
        <p>The registration process was handled through a multi-step form, which fetched car details from an external API and gathered additional information from the user. Users could choose from multiple warranty plans, and payments were processed via a PayPal gateway.</p>
        <p>The site included a dashboard where users could manage their warranties, view purchase history, and update personal details in the profile section. Redux was used for state management, and Axios was employed for API calls. I also enhanced the admin panel by improving the pagination and search functionalities, making the platform more efficient and scalable.</p>
      </>
    },
    {
      icon:<FaRegRegistered />,
      title: 'Business Registration Site',
      subtitle: 'A business registration site in the USA, focusing on backend development and user dashboard creation.',
      desc: <>
        <p>This project was centered around developing a business registration platform for users in the USA. I primarily worked on the backend, where I was responsible for creating the schemas, controllers, and APIs necessary for the platform's operation.</p>
        <p>Additionally, I contributed to the UI design for various plan options available to users and developed a comprehensive user dashboard. This dashboard allows users to manage their business registration details efficiently, providing a seamless and user-friendly experience.</p>
      </>
    } 
   ];
  
  
   const mobileAppProjects = [
    {
      icon: <MdFrontLoader />,
      title: 'Mahindra Construction Equipment App',
      subtitle: 'A React Native app for scanning machinery parts and retrieving sub-part data.',
      desc: <>
        <p>I developed a React Native app using Expo for Mahindra Construction Equipment. The app was designed to scan the QR code of the main part of the machinery, which would fetch the relevant sub-part data from the backend.</p>
        <p>After retrieving the data, users could scan the QR code of each sub-part to get its actual value and compare it against the required value set on the backend. The app featured color-coded results: green if the value matched, and red if it did not. This functionality was integrated with a history feature that saved the data for future reference.</p>
      </>
    }
  ];
  

  return (
    <section id='projects'
    className='position-relative'
     >
        <Container className='py-5' >
         <h2 className='text-center' data-aos='fade-up'>Projects</h2>
         <p className='text-center' data-aos='fade-up'>Web App projects</p>
        <Row md={3} sm={3} xs={2} className=''>
          {webAppProjects?.map((item,index)=>(

          <Col key={index} className='p-2' >
            <div className='h-100' data-aos='fade-up'  data-aos-delay={300*index} >
          <div  className={` project-card rounded-3  p-3`} onClick={()=>handleSelectedProject(item)}>
            {/* <div className='h-100 project-div rounded-3 text-center'> */}
            <div className='text-center d-flex w-100 justify-content-center'>

            <div className=' project-icon-container' >
              <div className='project-icon'>{item?.icon}</div>
              </div>
            </div>
              <p className='text-center fs-5 mb-0 '>{item?.title}</p>
              <p>{item?.subtitle}</p>
              {/* </div> */}
          </div>
          </div>
          </Col>
          ))}
        </Row>


         <p className='text-center mt-5' data-aos='fade-up'>Mobile App Projects</p>
        <Row md={3} sm={3} xs={2} className='justify-content-center'>
          {mobileAppProjects?.map((item,index)=>(

          <Col key={index} className='p-2'>
            <div className='h-100' data-aos='fade-up'  data-aos-delay={300*index}>
          <div className={` project-card rounded-3 p-3`} onClick={()=>handleSelectedProject(item)}>
            {/* <div className='h-100 project-div rounded-3 text-center'> */}
            <div className='text-center d-flex w-100 justify-content-center'>

            <div className=' project-icon-container' >
              <div className='project-icon'>{item?.icon}</div>
              </div>
            </div>
              <p className='text-center fs-5 mb-0'>{item?.title}</p>
              <p>{item?.subtitle}</p>
              {/* </div> */}
          </div>
          </div>
          </Col>
          ))}
        </Row>

        </Container>

            <Modal show={showModal} onHide={handleHideModal} size='lg' centered className='glass-morf'>
              <div className='project-card p-1 rounded-2'>
                <div className='project-div rounded-2'>
              <Modal.Header closeButton>
                      {selectedProject?.title}
              </Modal.Header>
              <Modal.Body>
                 <p className='text-center' style={{fontStyle:'italic'}}>{selectedProject?.subtitle}</p>
                <div className='text-center text-justify'>{selectedProject?.desc}</div>
              </Modal.Body>
              </div>
              </div>
            </Modal>
    </section>
  )
}

export default Projects