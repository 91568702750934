import React, { useState } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import './ContactMe.css';
import { toast } from 'react-toastify';
import axios from 'axios';

const ContactMe = () => {

  const [form,setForm]= useState({});
  const [loading,setLoading] = useState(false);
  const toastOptions = {
    position: "top-right",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "colored",
  };
  const handleChange = (e)=>{
    const {name,value} = e.target;
     setForm({...form,[name]:value});
  }

  const handleSubmit = async(e)=>{
    e.preventDefault();
    try {
      setLoading(true)
      const data = await axios.post('https://ahpg93odcc.execute-api.eu-north-1.amazonaws.com/prod/sendemail',form)
      toast.success(data?.data,toastOptions)
      setForm({
        name:'',
        email:'',
        message:'',
      });
      setLoading(false)
      
    } catch (error) {
      setLoading(false)
      console.log(error)
      toast.error(error?.message || "Something went wrong")
    }
  }

  return (
    <section id='contact' className="contact-section">
      <Container className='d-flex flex-column align-items-center py-5'>
        <h2 className='text-center'>Contact Me</h2>
        <Form 
          name="contact"  
          onSubmit={handleSubmit}
          className="contact-form-container glass-morf rounded-4"
        >
          <input type="hidden" name="form-name" value="contact" />

          <input type="hidden" name="bot-field" />

          <Form.Group className="input-box mb-3" controlId="formName">
            <Form.FloatingLabel label="Name">
              <Form.Control type="text" name="name" onChange={handleChange} value={form?.name} placeholder="Name" required />
            </Form.FloatingLabel>
          </Form.Group>

          <Form.Group className="input-box mb-3" controlId="formEmail">
            <Form.FloatingLabel label="Email">
              <Form.Control type="email" name="email" value={form?.email} onChange={handleChange} placeholder="Email" required />
            </Form.FloatingLabel>
          </Form.Group>

          <Form.Group className="input-box mb-3" controlId="formMessage">
            <Form.FloatingLabel label="Message">
              <Form.Control as="textarea" name="message" value={form?.message} onChange={handleChange} placeholder="Leave a message here" style={{ height: '100px' }} required />
            </Form.FloatingLabel>
          </Form.Group>

          <Button
            type='submit'
            className="main-button fw-bold mx-auto"
            disabled={loading}
          >
            <span className='px-3 p-2'>
            {loading?
            <Spinner size='sm' animation='grow' role='status'/>
          :
            'Submit'
            } 
            </span>
          </Button>
        </Form>
      </Container>
    </section>
  );
};

export default ContactMe;
