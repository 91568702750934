import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { scroller } from 'react-scroll';
import { menuItems } from './MainNavbar';
import SocialIcons from './SocialIcons';

const Footer = () => {

  const handleScroll = (id) => {
    scroller.scrollTo(`${id}`, {
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <footer className="footer-section ">
      <Container>
        <Row className="text-center text-md-left">
          <Col md={4}>
            <p className="footer-title fs-5 mb-1">About Me</p>
            <p>I am a passionate developer working on various web and mobile app projects. Reach out to me for collaborations or freelance opportunities.</p>
          </Col>
          <Col md={4}>
            <p className="footer-title fs-5 mb-1">Quick Links</p>
            <ul className="footer-links">
              {menuItems?.map((item,i)=>(

              <li key={item} onClick={()=>handleScroll(item.toLowerCase())}>{item}</li>
              ))}
         
            </ul>
          </Col>
          <Col md={4} className="text-center text-md-right">
            <p className="footer-title fs-5 mb-1">Follow Me</p>
            <SocialIcons/>
          </Col>
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <p className="copyright">© {new Date().getFullYear()} Siddhant Gore. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
