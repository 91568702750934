import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Skills from '../sections/Skills'
import Hero from '../sections/Hero'
import AboutMe from '../sections/AboutMe'
import Projects from '../sections/Projects'
import ToolsUsed from '../sections/ToolsUsed'
import Experience from '../sections/Experience'
import ContactMe from '../sections/ContactMe'
// import SkillsNew from '../sections/SkillsNew'

function Home() {

 console.log('Have fun inspecting 😉')

  return (
    <>
    
         <Hero/>
         <AboutMe/>
          <Skills/>
          <Experience/>
          <Projects/>
          <ToolsUsed/>
          <ContactMe/>
          {/* <SkillsNew/> */}
  </>
  )
}

export default Home