import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectTheme, toggleTheme } from '../features/themeSlice';
import './ThemeToggler.css';
import { MdDarkMode, MdLightMode } from 'react-icons/md';

function ThemeToggler() {

    
    // const [theme, setTheme] = useState('light');
 const dispatch = useDispatch();
 const {theme} = useSelector(selectTheme);

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const handleToggleTheme = () => {
        // setTheme(theme === 'light' ? 'dark' : 'light');
        dispatch(toggleTheme());
    };


  return (
    <div className="theme-toggle" onClick={handleToggleTheme}>
      <div className={`toggle-btn ${theme === 'light' ? 'light' : 'dark'}`}>
        <div className="toggle-circle">
          {theme === 'light' ? <MdLightMode className="icon sun-icon" /> : <MdDarkMode className="icon moon-icon" />}
        </div>
      </div>
    </div>
  )
}

export default ThemeToggler