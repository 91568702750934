import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Typography, Paper } from '@mui/material';
import { FaBriefcase, FaChevronDown, FaGraduationCap, FaSchool } from 'react-icons/fa6';
import { Container } from 'react-bootstrap';
import './Experience.css'
import { PiCertificateFill } from 'react-icons/pi';

const ExperienceTimeline = () => {


    const data = [
        {date:'Aug 2018 - June 2022',icon:<FaGraduationCap />,title:'BE Mechanical Engg.',subtitle:'Govt College of Engineering, Chandrapur',desc:'Bachelor of Engineering from GCOEC.'},
        {date:'Aug 2022 - Apr 2023',icon:<PiCertificateFill />,title:'PGDAC',subtitle:'CDAC Bangalore ',desc:'Post Graduate deploma in advanced computing.'},
        {date:'June 2023 - Present',icon:<FaBriefcase/>,title:'Full stack developer',subtitle:'Quantum It Innovation',desc:'Developed web applications using MERN Stack.'},
        // {date:'Sept 2018 - Dec 2020',icon:<FaSchool/>,title:'Full stack developer',subtitle:'XYZ Company',desc:'Developed web applications using React and Node.js.'},
    ]


  return (
    <section id='experience'>
    <Container className='text-center py-5' data-aos='fade-up' style={{overflowX:'hidden'}}>
        <h2 data-aos='fade-up'>Professional Experience Timeline</h2>
    <Timeline position="alternate" className='mt-5' >
        {data?.map((item,index)=>(
  <TimelineItem key={item?.title}>
  <TimelineOppositeContent>
     <p className='mt-2' style={{fontSize:'0.9rem'}}>{item?.date}</p>
    
  </TimelineOppositeContent>
  <TimelineSeparator>
    <TimelineDot sx={{ bgcolor: 'var(--primary-color)' }}>
      {/* <WorkIcon /> */}
      {/* <FaBriefcase/> */}
      {item?.icon}
    </TimelineDot>
    <TimelineConnector />
  </TimelineSeparator>
  <TimelineContent data-aos={`${index%2 === 0?'fade-left':'fade-right'}`}>
    <div className='rounded-2 p-2 timeline-div' >
        <p className='fs-5 mb-0'>
{item?.title}
        </p>
      <p className='m-0' style={{fontSize:'0.9rem'}}>{item?.subtitle}</p>
      <p>{item?.desc}</p>
    </div>
  </TimelineContent>
</TimelineItem>
        ))}

    <TimelineItem data-aos={'fade-down'}>
    <TimelineOppositeContent>
    <p className='mt-5'>To Come</p>
  </TimelineOppositeContent>
  <TimelineSeparator >
    <div style={{width:'1px',height:'50px',borderLeft:'2px dotted #c0c1c4'}}></div>
    <TimelineDot sx={{ bgcolor: 'var(--primary-color)' }}>
      <FaChevronDown />
    </TimelineDot>
        
  </TimelineSeparator>
  <TimelineContent >
   <p className='mt-5'>Lot More</p>
    
  </TimelineContent>
</TimelineItem>
    </Timeline>
    </Container>
    </section>
  );
};

export default ExperienceTimeline;
