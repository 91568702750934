import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/MainNavbar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer } from 'react-toastify';


function App() {
  
useEffect(()=>{
  AOS.init({
    duration: 2000,
    easing: 'ease-in-out',
    anchorPlacement: 'top-center'
  });

},[AOS])

  return (
    <>
        <Navbar/>
      <Routes>
        <Route path='/' element={<Home/>}/>
      </Routes>
      <Footer/>
      <ToastContainer/>
      </>
  )
}

export default App
