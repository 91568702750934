import React from 'react'
import { FaFacebookF,  FaGithub, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa6'
import './SocialIcons.css'
function SocialIcons() {
  return (
    <ul className="social-links">
              {/* <li><a href="https://facebook.com" target="_blank" rel="noreferrer"><FaFacebookF /></a></li> */}
              <li><a href="https://github.com/siddhant-gore" aria-label="Visit my GitHub profile"  target="_blank" rel="noreferrer"><FaGithub /></a></li>
              <li><a href="https://instagram.com/gore.siddhant" aria-label="Visit my Instagram profile" target="_blank" rel="noreferrer"><FaInstagram /></a></li>
              <li><a href="https://www.linkedin.com/in/siddhant-gore/" aria-label="Visit my linkedin profile" target="_blank" rel="noreferrer"><FaLinkedinIn /></a></li>
    </ul>
  )
}

export default SocialIcons