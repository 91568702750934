import React from 'react'
import { Container } from 'react-bootstrap'
import Carousel from '../components/Carousel'
import './ToolsUsed.css'
function ToolsUsed() {

    const tools=[
        {title:'HTML',imgSrc:'/html.svg'},
        {title:'CSS',imgSrc:'/css.svg'},
        {title:'JavaScript',imgSrc:'/javascript.svg'},
        {title:'React',imgSrc:'/react.svg'},
        {title:'Bootstrap',imgSrc:'/bootstrap.svg'},
    ]
    const tools2=[
        {title:'Postman',imgSrc:'/postman.svg'},
        {title:'Node',imgSrc:'/nodejs.svg'},
        {title:'Redux',imgSrc:'/redux.svg'},
        {title:'NPM',imgSrc:'/npm.svg'},
        {title:'React Native',imgSrc:'/react-native.svg'},
        {title:'Vite',imgSrc:'/vitejs.svg'},
    ]
    const tools3=[
        {title:'Express',imgSrc:'/express.svg'},
        {title:'MongoDb',imgSrc:'/mongodb.svg'},
        {title:'Figma',imgSrc:'/figma.svg'},
        {title:'AWS',imgSrc:'/aws.svg'},
        {title:'Trello',imgSrc:'/trello.svg'},
        {title:'Jira',imgSrc:'/jira.svg'},
        {title:'ChatGPT',imgSrc:'/chatgpt.svg'},
        {title:'Material UI',imgSrc:'/material-ui.svg'},
    ]

  return (
   <section id='tools' >
    <Container className='py-5'>
   <h2 data-aos="fade-up">Tools Used</h2>
   <div className='tools-slider-container rounded-5' data-aos="fade-up">
   <div className='tools-sliders py-5 rounded-5' data-aos="fade-up">
        <Carousel items={tools} />
        <br/>
        <Carousel items={tools2} rtl={true}/>
        <br/>
        <Carousel items={tools3} />
        </div>
    </div>
    </Container>
   </section>
  )
}

export default ToolsUsed