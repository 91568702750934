import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css'; 
import { Image } from 'react-bootstrap';

const Carousel = ({ items ,rtl=false}) => {
  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase:'linear',
    pauseOnHover: true,
    arrows: false,
    rows: 1,
    rtl:rtl
  };

  return (
    <Slider {...settings} data-aos="fade-up">
      {items.map((item, index) => (
        <div key={item?.title} className="carousel-item d-flex flex-column  align-items-center" style={{height:'50px'}}>
          <Image fluid src={item?.imgSrc} style={{height:'3.1rem'}} alt={item?.title} />
          <p className='text-center'>{item?.title}</p>
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
