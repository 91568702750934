import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Container, Nav, Navbar } from 'react-bootstrap';
import ThemeToggler from './ThemeToggler';
import { selectTheme } from '../features/themeSlice';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';

export const menuItems = ['Home', 'About', 'Skills', 'Experience', 'Projects', 'Contact'];

const MainNavbar = () => {
  const { theme } = useSelector(selectTheme);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('');

  const controlNavbar = () => {
    const scrollY = window.scrollY;

    if (scrollY > lastScrollY) {
      setShowNavbar(false);
    } else {
      setShowNavbar(true);
    }
    setLastScrollY(scrollY);
  };

  const updateActiveSection = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    const sections = menuItems.map(item => item.toLowerCase());

    sections.forEach(sectionId => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const sectionTop = sectionElement.offsetTop;
        const sectionBottom = sectionTop + sectionElement.clientHeight;

        if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
          setActiveSection(sectionId);
        }
      }
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        controlNavbar();
        updateActiveSection();
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleScroll = (id) => {
    scroller.scrollTo(`${id}`, {
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <Navbar
      className={`${showNavbar ? 'show-navbar' : ''} top-0 glass-morf`}
      bg={theme === 'light' ? 'light' : 'dark'}
      variant={theme === 'light' ? 'light' : 'dark'}
      expand="lg"
    >
      <Container>
        <Navbar.Brand href="#">$iddhant</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menuItems.map((item) => (
              <Nav.Link
                onClick={() => handleScroll(item.toLowerCase())}
                className={activeSection === item.toLowerCase() ? 'active' : ''}
                key={item} 
              >
                {item}
              </Nav.Link>
            ))}
          </Nav>
          <ThemeToggler />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
