import React from 'react';
import './AboutMe.css';
import { Container, Image } from 'react-bootstrap';

const AboutMe = () => {
  return (
    <section id='about' className='position-relative'>
      <Image alt='' src='./wall.webp' className='wall-img'/>
      <Container className="about-me p-lg-4 px-0 py-3 text-center">
        <div className="about-me-content px-5">
          <h2 data-aos='fade-up'>About Me</h2>
          <p data-aos='fade-up'>
            Hello! I'm Siddhant, your friendly neighborhood Full Stack Developer. Whether it's crafting pixel-perfect front-end designs or taming the wild logic of the back-end, I’m on it like a bug on code (except I actually fix bugs). I’m always on the lookout for the latest tech trends—like a cat chasing a laser pointer, but way more productive.
          </p>
          <p data-aos='fade-up'>
            Over the years, I’ve been on more coding adventures than I can count—seriously, my GitHub is a labyrinth. From tiny personal projects that nobody asked for (but I did anyway) to massive web applications that made my coffee consumption go up by 200%, I've done it all. My mission? To make the web a friendlier, prettier place—one line of code at a time.
          </p>
          <p data-aos='fade-up'>
            When I'm not coding, you might find me doing Art & Craft or just trying to figure out my existence. I’m always up for a new challenge—whether that’s debugging a tricky issue or finally learning how to cook something that isn’t ramen. So, what do you say? Let’s create something awesome together!
          </p>
        </div>
      </Container>
    </section>
  );
};

export default AboutMe;
