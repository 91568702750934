import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ skillsData }) => {
  return (
    <div className='progress-list'>
  {skillsData?.map((skill, index) => (
    <div key={skill?.skill} className="progress-bar-container mb-2" data-aos="fade-up">
      <p className="skill-name mb-0 ">{skill?.skill}</p>
      <div className="progress-bar ">
        <div className="progress-bar-fill" style={{ width: `${skill?.percentage}%` }}>
          <span className="progress-bar-text">{skill?.percentage}%</span>
        </div>
      </div>
    </div>
  ))}
    </div>
  );
};

export default ProgressBar;
