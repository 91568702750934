import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  theme: localStorage.getItem('theme')?JSON.parse(localStorage.getItem('theme')):'dark'
}


const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) =>{
     state.theme = state.theme === 'light' ? 'dark' : 'light';
     localStorage.setItem('theme',JSON.stringify(state.theme));
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export const selectTheme = (state) =>state.theme;
export default themeSlice.reducer;
