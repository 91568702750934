import React, { useEffect, useState } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import './Hero.css';
import { Button, Container, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectTheme } from '../features/themeSlice';
import {  scroller } from 'react-scroll';
import { FaChevronDown } from 'react-icons/fa6';
import SocialIcons from '../components/SocialIcons';

const Hero = () => {

  const {theme} = useSelector(selectTheme)

  const [show,setShow] = useState(false);



  const scrollToAbout = () => {
    scroller.scrollTo('about', {
      duration: 5000,
      smooth: 'easeInOutQuint',
    });
  };

  

  useEffect(() => {
    let ticking = false;
  
    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const scrollY = window.scrollY;
          const cloud1 = document.querySelector('.cloud-1');
          const cloud2 = document.querySelector('.cloud-2');
          const branch = document.querySelector('.branch-img');
          const rock = document.querySelector('.rock-img');
          const jungle = document.querySelector('.jungle-img');
          const moon = document?.querySelector('.moon');
  
          // cloud1.style.transform = `translateX(${scrollY * -0.5}px)`;
          // cloud2.style.transform = `scaleX(-1) translateX(${scrollY * -0.5}px)`; 
          branch.style.transform = `translateX(${scrollY * -0.3}px)`; 
          rock.style.transform = `translateX(${scrollY * 0.3}px)`; 
          jungle.style.transform = `translateY(${scrollY * 0.15}px)`; 
          if (moon) {
            moon.style.transform = `translateY(${scrollY * 0.5}px)`; 
          }
  
          ticking = false;
        });
  
        ticking = true;
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  return (
    <section className="hero" id='home'>

     {theme === 'dark' ?
     <>
     <div className="star star-1"></div>
<div className="moon"></div>
<div className="star star-2"></div>
<div className="shooting-star"></div>
     </>
     :
     <div className="sun"></div>
     
    }
      
    <div className='grass-div'></div>
      <Image src='/jungle.webp' alt='' className={`jungle-img ${theme === 'light'?'day':''}`} />
      <Image src='/rock.webp' alt='' className='rock-img' />
      <Image src='/branchBlack.webp' alt='' className='branch-img' />
      {/* <Image src='/mountain.webp' className='mountain-img' /> */}
      <Image src='/cloud.webp' alt='' className='cloud-img cloud-1' />
      <Image src='/cloud.webp' alt='' className='cloud-img cloud-2' />
      <Image src='/fog.webp' alt='' className='fog-img' />
      <Image src='/leaf.webp' alt='' className='leaf-img' />

      <Container className='h-100 w-100 d-flex justify-content-center' >
        <div className="hero-content text-center">
          <h1 className='gradient-text '>Hello, I'm Siddhant</h1>
          <h2 className="typing-text fs-3">
            I Am A {' '}
            <span>
              <Typewriter
                cursorBlinking
                words={['Designer', 'Developer', 'Creator']}
                loop={0} 
                cursor
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </h2>
          
         
          <button onClick={()=>setShow(true)}
           className="main-button explore-btn fw-bold mx-auto"><span >
       Explore More
            </span></button>

      
      {show &&
      <div className='mt-2' data-aos={'fade-down'}>
        <SocialIcons/>

        
            <h5>Scroll Down</h5>
            <FaChevronDown size={25}  className='down-arrow'/>
            </div>
      }

        </div>
      </Container>
    </section>
  );
};

export default Hero;
